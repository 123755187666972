import addLastVideo from "../../../../scripts/rebuild/add-last-video";
import redirectLink from '/src/scripts/rebuild/redirect-hidden-link.js'

const newsletter = document.querySelector('#newsletter-popup')
const newsletter_start = document.querySelector('.newsletter-popup__start')
const newsletter_finish = document.querySelector('.newsletter-popup__finish')

function newsletterNextStep(){
	console.log('newsletterNextStep')
	newsletter_start.classList.add('newsletter-popup--fadeout')
	setTimeout(() => {
		newsletter_finish.classList.add('newsletter-popup--fadein')
	}, 100)
}

let popupImgs = document.querySelectorAll(`#popup-thanks .img-hidden`);
function openPopupThanks(){
	let iframe = document.querySelector('#latest-video iframe')
	addLastVideo(iframe)
	redirectLink()
	popupImgs.forEach(el => {
		el.classList.remove('img-hidden')
	})
	document.querySelector('#popup-thanks').classList.add('open')
	console.log('openPopupThanks')
}
function closePopupNotification() {
	document.querySelector('#popup-notification').classList.remove('open')
	console.log('closePopupNotification')
}
function closePopupSubscribe(){
	document.querySelector('#popup-subscribe').classList.remove('open')
	console.log('closePopupSubscribe')
}
function closePopupForm(){
	document.querySelector('#popup-form').classList.remove('open')
	console.log('closePopupForm')
}
function openPopupSubscribe(){
	document.querySelector('#popup-subscribe').classList.add('open')
	console.log('openPopupSubscribe')
}
// const isPageLangFr = (document.documentElement.lang).includes('fr');
// const isUserLangFr = navigator.languages.indexOf('fr') === -1  ? false : true;
// const isMainPage = window.location.pathname === '/' || window.location.pathname === '/fr';
// const isFr = isPageLangFr || isUserLangFr;
// if(isFr) {
// 	openPopupUnavailable()
// }

//pop-up unavailable
;(async _ => {
	const response = await fetch('/check-country-blocked')
	const blockingData = await response.json()
	const { isBlocked, country } = blockingData
	// const { userIP } = await (await fetch('/get-user-ip')).json()
	// const { country } = await (await fetch(`https://ipinfo.io/${userIP}/json`)).json()
	// const countriesToBlock = [
	// 	'GB',
	// 	'FR'
	// ]
	// const isBlocked = countriesToBlock.includes(country)
	const isBot = /bot|crawler|spider|crawling/i.test(navigator.userAgent)

	console.table({
		// userIP,
		country,
		isBlocked,
		isBot
	})
	
	if (isBlocked && !isBot) {
		const textUpperEl = document.querySelector('.popup-text-upper')
		const textLowerEl = document.querySelector('.popup-text-lower')
		const message = {
			GB: {
				upper: "Atomic Wallet is temporarily unavailable for new users residing in the United Kingdom. If you are an existing user, your crypto funds in Atomic remain unaffected. We apologize for any inconvenience and are actively working to restore access in your country.",
				lower: "If you have any questions, feel free to contact us at "
			},
			FR: {
				upper: "Atomic Wallet est temporairement indisponible pour les nouveaux utilisateurs résidant en France. Si vous êtes un utilisateur existant, vos fonds en crypto-monnaies dans Atomic restent intacts.",
				lower: "Nous nous excusons pour tout désagrément et travaillons activement à rétablir l'accès dans votre pays "
			}
		}

		if (!message[country]) return console.warn(`No message configured for country: ${country}`)
	
		textUpperEl.innerText = message[country].upper
		textLowerEl.innerText = message[country].lower
		openPopupUnavailable()
	}
})()

function openPopupUnavailable() {
	const popup = document.querySelector('#popup-unavailable')

	popup.classList.add('open')
	document.body.style.overflow = 'hidden'
}
//

// function closePopupForm(){
// 	document.querySelector('#popup-form').classList.remove('open')
// 	console.log('closePopupForm')
// }

document.querySelectorAll('.subscribe-form, .news-form__form').forEach(item => {
	item.addEventListener('submit', async function(e) {
	e.preventDefault()
	const thisKey = this.getAttribute('data-key')
	const formData = new FormData(this)
	let data = {};

	formData.forEach((value, key) => data[key] = value);
	data = JSON.stringify(data);

	try {
		let res = await fetch('/form/subscribe', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: data
		})

		if( res.error ) {
			alert('Please, enter your email correctly.')
		} else {
			switch (thisKey) {
				case 'SUBSCRIBE_SECTION_KEY':
					openPopupThanks()
					break
				case 'SUBSCRIBE_POPUP_KEY':
					closePopupForm()
					closePopupSubscribe()
					openPopupThanks()
					break
				case 'SUBSCRIBE_NOTIFICATION_KEY':
					closePopupNotification()
					openPopupThanks()
					break
				case 'SUBSCRIBE_NEWSLETTER_KEY':
					if (newsletter) newsletterNextStep()
					break
			}
		}
	} catch(err) {
		alert('Please, enter your email correctly.')
		console.log('error:', err)
	}
	
})})
document.querySelectorAll('[data-download-link]').forEach(item => item.addEventListener('click', function() {
	openPopupSubscribe()
}))
let popups = document.querySelectorAll('.modal-overlay')
let buttons = document.querySelectorAll('[data-popup]')
buttons.forEach( btn => {
	btn.addEventListener('click',()=>{
		let popupName = btn.dataset.popup
		popups.forEach( popup => {
			popup.id == popupName? popup.classList.add('open') : false
			console.log(popup.id)
			document.querySelectorAll(`#${popup.id} .img-hidden`).forEach(el => {
				el.classList.remove('img-hidden')
			})
		})
	})
})
document.querySelectorAll('.modal-window__close').forEach( btnClose => {
	btnClose.addEventListener('click',()=>{
		btnClose.closest('.modal-overlay').classList.remove('open')
	})
})
popups.forEach( popup => {
	if(popup.getAttribute('id') !== 'popup-unavailable') {
		popup.addEventListener('click', e=>{
			if(e.target.classList.contains('modal-overlay')){
				popup.classList.remove('open')
				let video = popup.querySelector('#popup-video .modal-window .video-card')
				video? popup.querySelector('#popup-video .modal-window .video-card').innerHTML = '' : false
			}
		})
	}
})
document.querySelectorAll('.watch-video-button, .newsletter-popup__button, .staking-btn, .video-button').forEach(btn => {
	btn.addEventListener('click', function() {
		const href = this.dataset.video
		let iframe = document.createElement( 'iframe' )
		iframe.setAttribute( 'allowfullscreen', '' )
		iframe.setAttribute( 'frameborder', '0' )
		iframe.setAttribute( 'allow', 'autoplay' )
		iframe.setAttribute( 'src', 'https://www.youtube.com/embed/'+href+'?autoplay=1' )
		document.querySelector('#popup-video .modal-window .video-card').appendChild( iframe )
	})
})
